import "./OverlayLoader.css";
const OverlayLoader = () => {
  return (
    <>
      <div className="overlay">
        <div className="overlay__inner">
          <div className="overlay__content">
            <span className="spinner" />
          </div>
        </div>
      </div>
    </>
  );
};

export default OverlayLoader;
