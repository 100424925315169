import { useEffect, useRef, useState } from "react";
import VideoPlayer from "../components/videoPlayer/VideoPlayer";
import { useParams } from "react-router-dom";
import { BASE_URL2, getApiCall } from "../utils/services";
import SideVideos from "./SideVideos";

const Training = () => {
  const playerRef = useRef(null);
  const params = useParams();
  
  
  const [selectedVideo, setSelectedVideo] = useState({});
  const [otherVideos, setOtherVideos] = useState([]);
  const { id } = params;
  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on("waiting", () => {
      console.log("player is waiting");
    });

    player.on("dispose", () => {
      console.log("player will dispose");
    });
  };
 


  useEffect(() => {
    getApiCall(
      `video/getVideos?brand=All`,
      (res) => {
        let selectedVids = null;
        let otherVids = [];
      
        res?.forEach((video) => {
          if (video._id === id) {
            selectedVids = video;
          } else {
            otherVids.push(video);
          }
  
        });
        setSelectedVideo(selectedVids)
        setOtherVideos(otherVids);      },
      (err) => {},
      true
    );
  }, [id]);

  const url =
    BASE_URL2 +
    "video/" +
    selectedVideo?.url?.substring(selectedVideo?.url?.lastIndexOf("/") + 1);
  console.log(url, "url");
  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    poster: selectedVideo?.thumbnailUrl,

    sources: [
      {
        src: url,

        type: "video/mp4",
      },
    ],
  };

  return (
    <div className="my-[150px] mb-[80px] flex flex-col gap-y-12 md:gap-y-0 md:flex-row md:justify-between w-[95%] 2xl:w-[90%] mx-auto ">
      <div className="w-full md:w-[65%] ">
        <div className=" w-full  h-auto ">
          {selectedVideo?.thumbnailUrl && selectedVideo?.url ? (
            <VideoPlayer options={videoJsOptions} onReady={handlePlayerReady} />
          ) : (
          <VideoSkeleton className="h-[60vh] w-full"/>
          )}{" "}
        </div>

        {selectedVideo?.title ? (
          <h2 className="text-sm md:text-lg lg:text-2xl my-3 font-bold">
            {selectedVideo?.title}
          </h2>
        ) : (
          <TextSkeleton lines={1} />
        )}

        {selectedVideo?.description ? (
          <p className="text-xs md:text-sm lg:text-xl text-gray-600 font-normal bg-gray-200 p-3 pb-12">
            {selectedVideo?.description}
          </p>
        ) : (
          <TextSkeleton lines={3} />
        )}
      </div>
      <div className="w-full grid grid-cols-2 sm:grid-cols-3 gap-2 md:block md:gap-0 md:w-[30%]">
        {otherVideos?.length>0?otherVideos?.map((video, idx) => {
          return <SideVideos video={video} key={idx} />;
        })
        :
        Array.from({length:8},(_,i)=>{
          return(
            <VideoSkeleton key={i} className="h-[30vh] w-full mb-2"/>
          )
        })

        }
      </div>
    </div>
  );
};

export default Training;

const TextSkeleton = ({ lines }) => {
  return (
    <div role="status" className="w-full my-3 animate-pulse">
      {Array.from({ length: lines }, (_, idx) => (
        <div
          key={idx}
          className="h-4 bg-gray-200 rounded-full  w-full mb-1"
        />
      ))}
      <span className="sr-only">Loading...</span>
    </div>
  );
};
const VideoSkeleton = ({ className }) => {
  return (
    <div
    role="status"
    className={`flex items-center justify-center ${className}  bg-gray-300 rounded-lg animate-pulse `}
  >
    <svg
      className={`w-10 h-10 text-gray-200 `}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 16 20"
    >
      <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
      <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM9 13a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2Zm4 .382a1 1 0 0 1-1.447.894L10 13v-2l1.553-1.276a1 1 0 0 1 1.447.894v2.764Z" />
    </svg>
    <span className="sr-only">Loading...</span>
  </div>
  );
};
