import React, { useRef } from "react";
import "./Modal.css";
import useScrollBlock from "../../utils/hooks/useScrolbar";
const Modal = ({show,setShow,children,animation="bottom"}) => {
  const enteranceClass=`slide-in-${animation}`
  const exitClass=`slide-out-${animation==="right"?"left":animation}`
  const modalRef = useRef(null);
   const toggleScroll =useScrollBlock()
  const openModal = () => {
    setShow(true);
  };
  const closeModal = () => {
    if (modalRef.current) {
      modalRef.current.classList.remove(enteranceClass);
      modalRef.current.classList.add(exitClass);
      console.log("scroll")

      // Delay hiding the modal until after the transition finishes
      setTimeout(() => {
        setShow(false);
        toggleScroll(false)

        modalRef.current.classList.remove(exitClass);
        modalRef.current.classList.add(enteranceClass); // Reset for future opens
      }, 300); // 300ms matches the transition duration (should match your CSS)
    }
  };


  return (
    <>
      {/* Main modal */}
      {show && (
        <div
          id="select-modal"
          className={`.slide-in-bottom overflow-y-hidden overflow-x-hidden fixed bg-black bg-opacity-60 top-0 right-0 left-0 ma z-50 justify-center items-center w-full md:inset-0 h-full`}
        >
          {React.cloneElement(children, {show, closeModal,openModal,modalRef })}

        </div>
      )}
    </>
  );
};

export default Modal;
